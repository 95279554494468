#pricing-page {
	#pricing-boxes{

	}
	.package-details{
		border-radius: 8px;
		color: #FFF;
		padding: 0 0 10px;
		margin-bottom: 2em;

		h3{
			border-radius: 8px 8px 0 0;
			color: #FFF; 
			margin: 0;
			padding: .75rem;
		}
		.price{
			font-size: 3em;
			font-weight: bold;
			padding: .5rem 0;
			padding: 1rem 0 .5rem;
			margin-bottom: 0;
			text-shadow: 0px 1px 0px white;
		}
		p{
			color: #333;
			font-weight: bold;
			margin-bottom: 1rem;
		}
		.button {
			font-size: 1rem;
			font-weight: bold;
		}
	}
	.package-features{
		h4{
			border-bottom: 3px solid #EDEDED;
			text-align: left;
			margin-bottom: 1rem;
		}
		ul > li{
			border-bottom: 1px solid #EBEBEB;
			font-size: .9rem;
			list-style: disc;
			margin-left: 1.5rem;
			margin-bottom: .5em;
			padding-bottom: .5em;
			text-align: left;
		}
	}
    table {
        margin: 20px 0;
        border: none;
        thead {
            background: none;
            tr th {
                background: $off-white;
                text-align: center;
                border-top: 1px solid $pale-grey;
                @include box-shadow(inset -1px 0 0 $pale-grey);
                &:first-child {
                    background: none;
                    border-top: none;
                    @include box-shadow(none);
                }
                &:nth-child(2) {
                    @include box-shadow(inset -1px 0 0 $pale-grey);
                }
            }
        }
        tbody {
            border-bottom: 1px solid $pale-grey;
            tr td {
                text-align: center;
                @include box-shadow(inset -1px 0 0 $pale-grey);
                &:first-child {
                    text-align: left;
                    background: #4AA7E5;
                    border: 1px solid #3D85AD;
                    color: $white;
                    text-shadow: 0 1px 2px #0078A7;
                    @include box-shadow(inset 0 1px 0 0 rgba(255, 255, 255, 0.14));
                }
            }
        }
        .button {
            display: block;
            margin: 10px 0 0;
            padding: 10px 0;
            font-size: 14px;
        }
    }
}
