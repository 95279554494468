$primary-color: #1F8BCE;
$secondary-color: #FF5A00;
$primary-dark: #595b5e;
$white: #fff;
$off-white: #F0F1F5;
$green: #1A8700;
$hover-green: #276F16;
$pn-green: #21b271;
$pale-grey: #ddd;

$primary-green: #0a6b71; //Dark Green
$secondary-green: #0bc5c2; //Light Green


