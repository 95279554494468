@font-face {
    font-family: BebasNeue;
    src: url(/fonts/BebasNeue-webfont.woff);
}
.mobile-hide{
	@include bp(small){
		display: none !important;
	}
}
.mobile-show{
	display: none;
	@include bp(small){
		display: block !important;
	}
}
.row.full-width{
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: initial;
}

#home-page {
	/*
     * Banner Section
     */
	#home-banner {
		height: 300px;
		background-image: url('/images/marketing/Localtunity_ForBusiness-Header.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position-x: right;
		display: flex;
		align-items: center;
		justify-content: center;

		.home-banner-text {
			color: #fff;
			& > * { color: inherit; }
			text-align: center;
			background-color: rgba(0, 0, 0, .5);
			border-radius: 5px;
			padding: 10px;

			h1 {
				text-transform: uppercase;
				font-size: 1.5rem;
				font-weight: 700;
			}
			h3, span { font-size: 1rem; }
		}

		@media screen and (min-width: 1000px) {
			height: 700px;
			justify-content: left;
			padding-left: 3rem;

			.home-banner-text {
				max-width: 700px;
				background-color: initial;
				h1 { font-size: 3.2rem; }
				h3, span { font-size: 2rem; }
			}
		}
	}



	#industries-section {
		text-align: center;
		padding: 60px 15px;

		h2 {
			font-weight: bold;
		}

		.industries-container {
			padding-top: 60px;
			display: flex;
			flex-wrap: wrap;

			&__industry {
				width: 50%;
				padding: 7px 0 0 7px;
				position: relative;
			}

			&__industry-image {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&__industry-text {
              	color: #fff;
				position: absolute;
				top: 7px;
				left: 7px;
				right: 0;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1rem;
			}
		}

		@media screen and (min-width: 1000px) {
			padding: 60px;

			.industries-container__industry {
				width: 25%;
			}

			.industries-container__industry-text {
				font-size: 1.5rem;
			}
        }
	}

	#icons-section {
		padding: 30px 15px 60px 15px;
		text-align: center;

		h2 {
			font-weight: bold;
		}

		h3 {
			margin: 60px 0;
		}

		.icons-headline {
			font-size: 1.5rem;
		}

		.icons-container {
			display: flex;
			flex-wrap: wrap;

			&__icon {
				width: 50%;
				padding: 0 0 60px 7px;
				font-size: 1.3rem;
			}

			&__icon-image::before {
				font-size: 80px;
				margin: 0;
			}
		}

		@media screen and (min-width: 1000px) {
			max-width: 1600px;
			margin: 0 auto;

			.icons-headline {
				font-size: 2rem;
			}

			.icons-container__icon {
              width: 25%;
			}
		}
	}

	/*
     * Mobile VIPS Section
     */
	#mobile-vips-section {
		height: 500px;
		padding: 0 1rem;
		background-image: url('/images/marketing/Localtunity_MobileVIPs-Background.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
		background-position-x: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
		& > * { color: inherit; }

		h2 {
			font-size: 1.2rem;
			font-weight: 700;
		}
		h3 { font-size: 1rem; }
		img {
			width: 260px;
			margin-top: 4rem;
		}

		@media screen and (min-width: 1000px) {
			height: 700px;
			padding: 0 300px;

			h2 { font-size: 2.5rem; }
			h3 { font-size: 2rem; }
			img {
				margin-top: 1rem;
				width: 350px;
			}
		}
	}



	/*
     * Customers Section
     */
	#customers-section {
		text-align: center;
		padding-top: 60px;
		h2 {
			font-weight: bold;
		}

		img  { max-height: 650px; }
		.customers-section-image-left { display: none; }

		@media screen and (min-width: 1000px) {
			.customers-section-image-left { display: inline; }
		}
	}


	/*
     * Promote Your Business Section
     */
	#promote-your-business-section {
	}



	/*
     * Loyalty Rewards Section
     */
	#loyalty-rewards-section {
		padding: 1rem 1rem 4rem 1rem;

		h1 {
			font-size: 1.4rem;
			text-align: center;
		}

		.loyalty-rewards-section-flex-wrapper {
			display: flex;
			flex-direction: column;
			> div { flex: 1; }

			.loyalty-rewards-section-app-slider {
				display: flex;
				justify-content: center;
			}
		}

		.loyalty-rewards-section-cards-tagline {
			color: #000;
			font-weight: 600;
			font-size: 1.5rem;
			margin-top: 1rem;
		}

		.rewards-carousel { max-width: 400px; }

		ul.features {
			li {
				font-size: 1rem;
				margin: 1rem 0;
				&::before {
					background: url('/images/marketing/loyalty-feature-bullet.png');
					background-size: 100%;
					content: '';
					display: inline-block;
					margin-right: 1rem;
					height: 20px;
					width: 20px;
				}
			}
		}

		@media screen and (min-width: 1000px) {
			.loyalty-rewards-section-flex-wrapper { flex-direction: row; }

			h1 {
				font-size: 2rem;
				margin: 1rem 0;
			}
		}
	}



	/*
     * Review Section
     */
	#review-section {
		height: 500px;
		background-image: url('/images/marketing/Localtunity_VideoReviews-blank.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;

		.review-section-wrapper {
			text-align: center;
			font-size: 1.5rem;
			text-align: center;
			background-color: rgba(0, 0, 0, .5);
			border-radius: 5px;
			padding: 10px;
		}
		.review-section-tagline {
          color: #fff;
		}
      	.review-section-icons {
			display: flex;
			justify-content: center;

			img	{
				max-width: 100px;
				padding: 0 15px;
			}
		}

		@media screen and (min-width: 1000px) {
			height: 700px;
			justify-content: flex-end;
			padding-right: 150px;

			.review-section-wrapper {
				max-width: 600px;
				font-size: 3rem;
				background-color: initial;
			}
		}
	}



	/*
     * Third Party Integrations Section
     */
	#third-party-integrations-section {
		padding: 1rem;
		text-align: center;

		.third-party-integrations-section-tagline {
			font-size: 1.3rem;
			margin: 1.5rem 0;
		}

		@media screen and (min-width: 1000px) {
			.third-party-integrations-section-tagline { font-size: 2.5rem; }
		}
	}
		/*
         * Connect With Customers Section
         */
	#connect-with-customers-section {
		height: 300px;
		background-image: url('/images/marketing/Localtunity_LocalCustomers-blank.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;

		.connect-with-customers-section-tagline {
			color: #fff;
			font-size: 1.3rem;
			background-color: rgba(0, 0, 0, .5);
			border-radius: 5px;
			padding: 10px;
			text-align: center;
		}

		@media screen and (min-width: 1000px) {
			height: 600px;

			.connect-with-customers-section-tagline {
				background-color: initial;
				font-size: 2.5rem;
				font-weight: 600;
			}
		}
	}

	.call-to-action-button {
		color: #fff;
		display: inline-block;
		margin-top: 20px;
		font-size: 1rem;
	}
	@media screen and (min-width: 1000px) {
		.call-to-action-button {
			font-size: 1.2rem;
			font-weight: 600;
		}
	}

}

.location-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;

    &-content {
        background-color: white;
        padding: 30px;
        border-radius: 10px;
        text-align: center;
        max-width: 90%;
        width: 450px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

        h3 {
            color: #333;
            margin: 0 0 15px;
            font-size: 1.6rem;
            font-weight: 600;
        }

        .popup-description {
            color: #555;
            font-size: 1.1rem;
            line-height: 1.5;
            margin-bottom: 20px;
        }

        .popup-note {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            color: #666;
            font-size: 0.9rem;
            margin-bottom: 20px;
            
            .info-icon {
                color: #2196F3;
                font-size: 18px;
            }
        }

        .button {
            background-color: #2196F3;
            color: white;
            padding: 12px 24px;
            border-radius: 25px;
            font-size: 1.1rem;
            display: inline-flex;
            align-items: center;
            gap: 8px;
            border: none;
            transition: background-color 0.3s ease;
            cursor: pointer;

            &:hover {
                background-color: #1976D2;
            }

            .material-symbols-outlined {
                font-size: 20px;
            }
        }

        @media screen and (max-width: 480px) {
            padding: 20px;
            
            h3 {
                font-size: 1.4rem;
            }

            .popup-description {
                font-size: 1rem;
            }

            .button {
                padding: 10px 20px;
                font-size: 1rem;
            }
        }
    }
}
