.button {
    @include button;
    @include border-radius(4px);
    text-shadow: (1px 1px #7c7777);
    color: $white;
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    &:hover {
        background: darken($primary-color, 5%);
    }
}

.button-default {
    @include button;
    background: #ccc;
}

.button.small {
    font-size: 15px;
}

.button.outline {
    font-weight: bold;
    background: rgba($primary-dark, .5);
    text-shadow: 0 1px 2px rgba($primary-dark, .5);
    &:hover {
        font-weight: bold;
        color: $primary-dark !important;
        background: $off-white;
        text-shadow: none;
    }
}
