body#api-page {
    background: url('/images/connect.png');
    height: 100%;
    & * {font-family: 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;}
    .contain-to-grid, .top-bar, .top-bar .name h1 a, .top-bar * {
        background-color: #333;
        color: $white;
    }
    #wrapper {
        min-height: 100%;
        position: relative;
    }
    article.content {
        padding-bottom: 70px;
        a {
            color: #008CBA;
            &:hover {
                color: #0078A0;
            }
        }
    }
    footer {
        line-height: 70px;
        background: #f5f5f5;
        border-top: 1px solid #ddd;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .string { color: #666666; }
    .number { color: darkorange; }
    .boolean { color: blue; }
    .null { color: magenta; }
    .key { color: #0078A0; }
}
