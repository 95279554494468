@mixin theme($name, $color, $secondary, $tagline) {
    @if($tagline == null) {
      $tagline: $color;
    }

	.#{$name} {
      .bg-primary {
        background-color: $color !important;
        &.bg-transparent {
          background-color: rgba($color, .75) !important;
        }
      }
      .color-primary {
        color: $color !important;
      }
      .border-primary {
        border-color: $color !important;
      }
      .bg-secondary {
        background-color: $secondary !important;
        &.bg-transparent {
          background-color: rgba($secondary, .5) !important;
        }
      }
      .color-secondary {
        color: $secondary !important;
      }
      .border-secondary {
        border-color: $secondary !important;
      }
      .hover-color-primary:hover {
        color: $color !important;
      }
      &#consumer-page {
        #under-map h1 {
          background-color: $tagline;
          /*background-color: rgba($color, .9);*/
        }
      }
      .switch-pill .switch-input:checked + .switch-label {
        background-color: $color !important;
      }
      &.clean-consumer-tagline-background {
        &#consumer-page {
          #under-map h1 {
            background-color: #eeeeee;
            color: #0f0f0f;
            padding-top: 20px;
            border-top: none;
            margin-bottom: -40px;
          }
        }
      }
      .orbit-container .orbit-slides-container .orbit-caption h1 {
        color: $color;
      }
      ul.pagination li.current a {
        background: $color;
      }
      #howButton {
        background: $color;
      }
      .button, .top-bar-section ul li > a.button {
        background-color: $color;
        &:hover {
          background-color: darken($color, 5%);
        }
      }
      #search-clear {
        border: 2px solid $color;
        color: darken($color, 10%);
      }
      .filters-block__item--active {
        background-color: $color;
      }
      .search-block__search-button {
        background-color: $color;
      }
      &#pricing-page table {
        tbody {
          tr td {
            &:first-child {
              background-color: $color;
              border: 1px solid darken($color, 10%);
            }
          }
        }
      }
      &#home-page {
        #logo-section h1 { color: $color; }
        .separator-header { background-color: $color; }
        #business-listing-form-section .form-contain input[type="submit"] { background-color: $color; }
        #form-section input[type="submit"] { background-color: $color; }
      }
      .business-container-info {
        p {
          color: $color !important;
        }
        @media only screen and (min-width: 640px) {
          /*background-color: darken($color, 10%);*/
        }
      }
      #secondary-banner:before {
        background: $color;
        background: rgba($color, .7);
      }
      &#home-page #whos-using-localtunity:before,
      &#reseller-page #whos-using-localtunity:before {
        background: rgba($color, .7);
      }
      footer ul li {
        a:focus, a:hover {
          color: $secondary;
        }
      }
	}
}

@import "../../shared/theme";

@each $theme in $themes {
  @include theme(map-get($theme, "name"), map-get($theme, "primary"), map-get($theme, "secondary"), map-get($theme, "tagline"));
}
