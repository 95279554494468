.hero-container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  padding: 1rem;

  &__background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  &__main-heading, &__secondary-heading {
    color: #fff;
  }
  &__main-heading {
    font-size: 1.3rem;
  }
  &__secondary-heading {
    font-size: 1rem;
  }
  &__call-to-action {
    margin-top: 30px;
    font-size: 0.9rem;
  }

  @media screen and (min-width: 1000px) {
    height: 600px;

    &__main-heading {
      font-size: 3.5rem;
      font-weight: bold;
    }
    &__secondary-heading {
      font-size: 2.5rem;
    }
    &__call-to-action {
      font-size: 1.5rem;
    }
  }
}
