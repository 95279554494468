@media (min-width: 1441px) {
  .orbit-container {
    .orbit-slides-container {
      img {
        max-width: inherit;
        width: 100%;
      }
    }
  }
}

.orbit-container {
  // Slider Settings
  .orbit-slides-container {
    .orbit-caption {
      background: none;
      top: 0;
      height: 100%;
      padding: 100px 0;
      .caption-content {
        max-width: 45%;
      }
      h1 {
        color: $primary-color;
        line-height: 1.2;
        margin-bottom: 0;
        font-weight: bold;
        font-size: rem-calc(45);
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.33), 0 -1px 0 rgba(255, 255, 255, 0.13);
      }
      h3 {
        color: $primary-dark;
        font-size: rem-calc(26);
        margin-bottom: 25px;
        font-weight: 400;
      }
      .button {
        font-size: rem-calc(18);
        font-weight: 600;
        padding: 8px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        margin-bottom: 10px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
      }
    }
  }

  // Slider Actions
  .orbit-slide-number, .orbit-timer {
    display: none;
  }
  .orbit-prev, .orbit-next {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    @include transition-def(.2s opacity ease-in-out);
  }
  &:hover {
    .orbit-prev, .orbit-next {
      opacity: 1;
    }
  }
}