.content {
    overflow: hidden;
}
#top-section {
    text-align: center;
    padding: 15px 10px;
    //background: $off-white;
    border-bottom: 1px solid $pale-grey;
    h2 {
        margin-bottom: 25px;
    }
    ~ section {
        padding: 50px 10px;
        border-bottom: 1px solid $pale-grey;
    }
    + section {
        background: $off-white;
    }

    .form-section {
        padding: 15px 0;
    }
}

#top-section.alt {
    @extend .bg-primary;
    text-align: center;
    padding: 50px 10px;
    border-bottom: 1px solid $pale-grey;
    background: url(/images/marketing/business-bg-color.jpg) center repeat;
    background-size: contain;
    position: relative;
    + section {
        background: $white;
    }
    h2 {
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);
    }
    select {
        border: none;
        @include box-shadow(0 0 0 6px rgba(0, 0, 0, 0.18));
    }
    h2, div, p {
        position: relative;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        @extend .bg-transparent;
    }
}

#loyalty-solutions {
    h2 + p {
        margin-bottom: 50px;
    }
   h5 {
    text-transform: uppercase;
    font-weight: 700;
    @media (max-width: 64.063em) {
        text-align: center;
        + p {
            text-align: center;
        }
    }
   } 
   img {
    position: relative;
    top: 50px;
    @extend .hide-for-medium-down;
   }
}

#login-form {
    padding: 80px 10px;
}

.hero-section {
    margin-top: 50px;
    background-image: url("/images/marketing/LocaltunityMobileMarketing.jpg");
    background-size: cover;
    padding: 15px;
    text-align: center;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__main-heading, &__secondary-heading {
        color: #fff;
    }

    &__main-heading {
        text-shadow: 0 0 4px #000;
        font-weight: 600;
        font-size: 1.5rem;
        @media (min-width: 800px) {
            font-size: 3rem;
        }
    }

    &__secondary-heading {
        margin-top: -1rem;
        font-size: 1.1rem;
        font-weight: 100;
        @media (min-width: 800px) {
            font-size: 2rem;
        }
    }

    &__button {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 15px;
    }
}

.registration-steps-banner {
    text-align: center;
    padding: 15px;
    margin-top: 30px;

    &__main-heading, &__secondary-heading {
        color: #fff;
    }

    &__main-heading {
        font-weight: 600;
        font-size: 1.8rem;
    }

    &__secondary-heading {
        font-size: 1.4rem;
    }
}

.registration-steps-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.registration-step {
    min-width: 200px;
    max-width: 250px;
    flex: 1;
    text-align: center;
    margin: 15px 0;

    &__image::before {
      font-size: 120px;
    }
    &__heading {
        color: #343434;
        font-weight: 700;
        font-size: 1.3rem;
        margin-bottom:15px;
        height: 3.5rem;
    }
    &__description {
        font-size: 0.9rem;

        em {
            white-space: nowrap;
        }
    }
}
