/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

/* 
 Custom Localtunity Theme Settings
 */
.localtunity-slider {
    display: none;
}

.localtunity-slider-container {
    /*min-height: 409px;*/
}

.localtunity-slider.slick-initialized {
    display: block;
    margin-bottom: 0;

    img {
        width: 100%;
    }

    .slick-dots {
        bottom: 0;
        background: rgba(0, 0, 0, 0.25);
        width: auto;
        left: 50%;
        transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        border-radius: 5px;
        padding: 0 15px;

        li {
            vertical-align: middle;
        }

        li button:before {
            color: #fff;
            font-size: 12px;
        }
        li.slick-active button:before {
            color: #fff;
        }
    }
    @media (max-width: 40.063em) {
        display: none !important;
    }
    /*@media (max-width: 64.063em) and (min-width: 40.063em) {*/
        /*margin-top: 61px;*/
    /*}*/
    /*@media (max-width: 40.063em) {*/
        /*margin-top: 100px;*/
        /*[>display: none !important;<]*/
    /*}*/
}

.slick-caption {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);

    h1, h3 {
        color: white;
        text-shadow: 0 1px 7px rgba(0, 0, 0, 0.42);
    }

    h1 {
        color: #FFF;
        font-weight: 600;
        font-size: 55px;
        margin: 0;
        line-height: 1.2;
    }

    h3 {
        font-weight: 400;
        font-size: 28px;
        padding: 0 10px;
        margin: 0 0 30px;
        line-height: 1.3;
    }

    a.button {
        max-width: 280px;
        font-size: 17px;
    }
}
