    @font-face {
      font-family: "Flaticon";
      src: url("/fonts/flaticon/Flaticon.eot");
      src: url("/fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
      url("/fonts/flaticon/Flaticon.woff2") format("woff2"),
      url("/fonts/flaticon/Flaticon.woff") format("woff"),
      url("/fonts/flaticon/Flaticon.ttf") format("truetype"),
      url("/fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Flaticon";
        src: url("/fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
      }
    }

    .fi:before{
      display: inline-block;
      font-family: "Flaticon";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }

    .flaticon-network:before { content: "\f100"; }
    .flaticon-percentage:before { content: "\f101"; }
    .flaticon-edit:before { content: "\f102"; }
    .flaticon-price-tag:before { content: "\f103"; }
    .flaticon-save-money:before { content: "\f104"; }
    .flaticon-smartphone:before { content: "\f105"; }
    .flaticon-voucher:before { content: "\f106"; }
    .flaticon-shop:before { content: "\f107"; }
    .flaticon-infographic:before { content: "\f108"; }
    .flaticon-rating:before { content: "\f109"; }
    .flaticon-facebook:before { content: "\f10a"; }
    .flaticon-card:before { content: "\f10b"; }
    .flaticon-mobile-payment:before { content: "\f10c"; }

    $font-Flaticon-network: "\f100";
    $font-Flaticon-percentage: "\f101";
    $font-Flaticon-edit: "\f102";
    $font-Flaticon-price-tag: "\f103";
    $font-Flaticon-save-money: "\f104";
    $font-Flaticon-smartphone: "\f105";
    $font-Flaticon-voucher: "\f106";
    $font-Flaticon-shop: "\f107";
    $font-Flaticon-infographic: "\f108";
    $font-Flaticon-rating: "\f109";
    $font-Flaticon-facebook: "\f10a";
    $font-Flaticon-card: "\f10b";
    $font-Flaticon-mobile-payment: "\f10c";
