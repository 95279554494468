#howButton {
    color: $white;
    font-weight: 600;
    background: $primary-color;
    @include border-radius(4px !important);
    &:hover {
        background: darken($primary-color, 5%);
    }
    &:active {
        background: darken($primary-color, 7%);
    }
}
header {
    border-bottom: 1px solid $pale-grey;
	padding: 15px 0;

	.top-bar{
		height: auto;
		@include bp(large){
			height: 50px;
		}
		.name{
			height: auto;
		}
		&.expanded{
			z-index: 10;
			overflow: visible;
			.top-bar-section {
				margin-top: 7px;
				position: absolute;
				width: 100%;
				ul {
					background: $off-white;
					padding: 5px;
				}
			}
		}
		@include bp(large){

		}
	}
    .title-area {
        img {
            max-width: 220px;
            @media (max-width: 64.063em) {
                height: auto;
                max-height: 50px;
            }
        }
    }
    .top-bar-section {
		ul{
			background: $white;
			padding: .5em 0;
		}
        li {
            &.has-dropdown {
                .dropdown a {
                    color: $primary-dark;
					font-weight: 300;
                    background: darken($off-white, 5%);
                }
            }
            img {
                max-height: 30px;
            }
			a {
				background: $off-white;
				color: #0a0a0a;
				font-size: 13px;
				font-weight: 400;
				line-height: 3em;
				letter-spacing: 3px;
				text-transform: uppercase;
				padding: 0 20px;
				&:hover { color: #646469; }
			}
			a.button {
				color: $white;
				display: block;
				padding: 0 15px;
				line-height: 45px;
				top: 0;
			}
        }
    }
    @media (max-width: 64.063em) {
        border-bottom: 1px solid $pale-grey;
    }
}
#secondary-banner {
    background: url(/images/marketing/business-bg-color.jpg) center repeat;
    background-size: contain;
    position: relative;
    //margin-bottom: 30px;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: $primary-color;
        background: rgba($primary-color, .6);
    }
    h1 {
        color: $white;
        font-size: rem-calc(26px);
        line-height: 75px;
        margin: 0;
        text-shadow: 0 2px 2px rgba(0,0,0,.6);
    }
}
