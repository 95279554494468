.form-section {
    background: $white;
    text-align: center;
    padding: 50px 0;
    h2 {
        margin-bottom: 30px;
    }
    input {
        min-height: 45px;
        @include border-radius(4px);
    }
    label {
        color: #797979;
        font-weight: 600;
    }
    input[type="submit"] {
        padding: 10px;
        display: block;
        width: 100%;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700 !important;
        text-shadow: none !important;
        height: auto;
        min-height: inherit;
        @include border-radius(4px);
    }
    input::placeholder {
        color: #8a8a8a;
    }
    textarea {
        height: auto;
    }
    ul.alert {
        padding: 5px 10px;
        background: #F2E7CC;
        margin: 5px 0;
        border: 1px solid #E0D2AC;
        li {
            font-size: 13px;
            font-weight: 600;
            line-height: 1.8;
        }
        &.error {
            color: #8B3D3D;
            background: #EAB6B6;
            border: 1px solid #BD8585;
        }
    }
    .info-icon {
        float: right;
        margin-bottom: 5px;
    }
}
