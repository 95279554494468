@mixin box-sizing($box-sizing){
	-webkit-box-sizing: $box-sizing;
	-moz-box-sizing: $box-sizing;
	box-sizing: $box-sizing;
}
@mixin transition-def($transition) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin border-radius($border-radius) {
	-webkit-border-radius: $border-radius;
	-moz-border-radius: $border-radius;
	border-radius: $border-radius;
}

@mixin box-shadow($box-shadow) {
	-webkit-box-shadow: $box-shadow;
	-moz-box-shadow: $box-shadow;
	box-shadow: $box-shadow;
}

@mixin lato {
	font-family: 'Lato', sans-serif;
}

/* Media Queries
----------------*/

	@mixin bp($point) {
		// assume that the default is 'small'
		// usage: @include bp(large) { ... }
		@if $point == small {
			@media only screen and (max-width: 479px) { @content; }
		}
		@else if $point == medium {
			// ~640px
			@media only screen and (max-width: 40.063em) { @content; }
		}
		@else if $point == large {
			@media (max-width: 64.063em) { @content; }
		}
	}

