.aligncenter {
  text-align: center;
}
.container-fluid{
	.row{
		max-width: none;
		.column{
			padding: 4rem 0
		}
	}
}
#search-map img {
	max-width: none;
}

#content {
	padding-top: 30px;
	background-color: #ffffff;
}
.row {
	max-width: 75rem;
}

.map-item-info {
	padding: 15px;
	text-align: center;
	.title {
		color: #333;
		font-size: 15px;
		font-weight: bold;
	}
	.title-location, .address {
		font: 16px "Lato",sans-serif;
		color: #666;
	}
	.address {
		border-bottom: 1px dotted #CCC;
		margin-bottom: 10px;
		padding-bottom: 5px;
	}
	a.more-button {
		background: $green;
		color: $white;
		display: block;
		font-size: 13px;
		font-weight: bold;
		padding: 10px 10px;
		text-decoration: none;
		text-align: center;
	}
}

.alert-box {
	border-radius: 0;
}

.alert-success {
	width: 400px;
	background-color: #DDFFDD;
	background-image: url(/images/icon-yes.png);
	border-color: #99BB99;
	color: #339933;
	background-position: 14px 8px;
	background-repeat: no-repeat;
	border-radius: .6em;
	border-style: solid;
	border-width: 1px;
	font-size: 16px;
	font-weight: bold;
	list-style: none outside none;
	padding: 16px 6px 12px 58px;
}

.alert .success {
    color: green;
    padding: 15px;
    background-color: lightgreen;
}

#consumer-page {
	.content{
		width: 100%;
	}
	#under-map {
		min-height: 40px;
		* {color: $white;}
		h3 {
			font-weight: 400;
			margin: 0;
			background: rgba(23, 23, 23, 0.5);
			text-align: center;
			line-height: 69px;
			z-index: 1;
			position: relative;
			border-top: 1px solid #333;
			padding: 0 50px;
		}
	}
	#hero-contain{
		display: none;
		@include bp(medium) {
			display: block;
		}
		.slick-dots{
			margin-left: 0;
		}
	}
	.mobile-header-slider {
		.slick-dots{
			display: none !important;
		}

	}

    @media (min-width: 992px) {
        .search-results {
          padding: 0 20px 0 20px;
        }
    }

	@media (max-width: 992px) {
      #under-map{
        margin: 1px 0 1px 0;
        min-height: initial;
        //display: none;

        h3 {
          font-size: 16px;
          line-height: unset;
          padding: 4px 0 4px 0;
        }
      }

      #content {
        .filters-block {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
	}
}

#top-image {
	@media (max-width: 64.063em) {
		background: url('../../images/marketing/consumer-mobile-top-girl-thing.jpg');
		background-size: cover;
		min-height: 260px;
		width: 100%;
	}
	@media (max-width: 64.063em) and (min-width: 40.063em) {
		display: none;
	}
}


#search-form {
	margin: 20px calc(var(--bs-gutter-x)* .5);
    display: flex;
	flex-wrap: wrap;
	box-shadow: none;
	min-height: 50px;
	padding: 10px;
	width: 100%;

	@media (max-width: 64.063em) and (min-width: 40.063em) {
		min-height: 54px;
		width: 100%;
	}
	@media (max-width: 40.063em) {
		margin: 8px 0;
		width: 100%;
	}
	@include bp(small){
		width: 100%;
	}
}

#search-submit, #search-clear {
	border-radius: 0;
	font-size: 15px;
	letter-spacing: 0;
	text-shadow: none;
	height: 50px;
	@media (max-width: 64.063em) {
		height: 54px;
	}
}

#search-clear {
	background: white;
	color: #65686B;
	text-shadow: none;
	width: 97%;
	width: 100%;
}
.home-banner {
	padding-bottom: 30px;

	//@media (max-width: 64.063em) {
		//display: none;
	//}
}
.business-display {
	[class*="column"] + [class*="column"]:last-child {
		float: left;
	}
}

.top-bar {
    max-width: 66.5rem !important;
}

#business-listings-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 1vw;
  margin: calc(var(--bs-gutter-x)* .5);
  
  	.outer-business-container {
		width: 100%;
		max-width: none;

		.business-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			background: $white;
			border: 1px solid $pale-grey;
			position: relative;
			text-align: center;
			box-shadow: 1px 2px 3px rgba(0,0,0,.5);
			border-radius: 0px;
			display: flex;
			flex-wrap: wrap;

			.business-image {
				aspect-ratio: 1/1;
				overflow: hidden;

				.my-auto {
					width: 100%;
					
					img {
						object-position: center;
					}
				}

				.business-container-logo-img {
					height: 100px;
					width: 100px;
					margin: auto;
					object-fit: contain;
				}
			}

			.business-container-info {
				padding: 10px 10px;
				text-align: center;
				bottom: 0;
				background: $white;
				position: relative;

				p, h4 {
					display: block;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					position: relative;
				}

				h4 {
					margin: 0;
					z-index: 1;
					font-weight: 400;
					font-size: 16px;
					color: darken($primary-dark, 20%);
					text-shadow: none;
					line-height: 1.1;
				}

				p  {
					font-size: 13px;
					margin: 5px 0 0;
					color: lighten($primary-dark, 20%);
				}

				.rating-responsive {
					margin: 5px auto; /* Center on larger screens */
				}
				
				.secondary-info {
					width: 100%;
					top: initial;
					background: $white;
					padding: 4px 4px 0;
					font-weight: 600;
					left: 0;

					.distance-marker {
						min-height: 16px;
					
						.pin-icon{
							width: 15px;
							padding-right: 5px;
						}
					
						@media (max-width: 40.063em) {
							float: left;
						}
					}

					.icons {
						font-size: 12px;
						margin: 0;
						font-weight: 600;
						color: lighten($primary-dark, 10%);
						img {
							max-width: 20px;
							margin: 3px auto;
							&.icon-were-hiring { max-width: 60px; }
						}
						span {
							background: $off-white;
							padding: 5px;
						}
					}
				}

				.has-deal {
					color: #03AD00;
				}
			}
		}
	}

	@media only screen and (max-width: 768px) {
		grid-template-columns: unset;
        display: flex;
        flex-direction: column;

		.outer-business-container {
			margin: 4px 0;
			max-width: unset !important;

			.business-container {
				flex-direction: row;
				border: none;
				border-bottom: 1px solid $off-white;
				background: #FFF;
				margin: 0px !important;
		
				&:hover {
					top: inherit;
					box-shadow: 2px 3px 10px rgba(0, 0, 0, 1);
					border: none;
				}

				> a {
					z-index: 99;
				}

				.business-image {
					.business-container-logo-img {
						margin-top: 0;
					}
				}

				.business-container-info {
					color: $primary-dark;
					text-align: left;
					position: relative;
					padding: 10px  5px 5px 5px;
					background: none;
					h4 {
						font-weight: 600;
						font-size: 16px;
						/*color: lighten($primary-dark, 20%);*/
						color: #545454;
						text-shadow: none;
						line-height: 1.1;
						margin-right: 10px;
					}
					p {
						font-weight: 400;
						font-size: 13px;
						color: lighten($primary-dark, 10%);
						text-shadow: none;
						margin: 7px;
					}
					.city-keyword {
						margin: 0;
						font-size: 12px;
						color: #656363;
						font-weight: 600;
					}
					.keyword {
						display: block;
						margin: 0;
						font-size: 12px;
						color: #656363;
						font-weight: 600;
						text-transform: capitalize;
					}

					.rating {
						.rating-responsive {
							margin: 5px 0; /* Center on larger screens */
						}
					}
				}
			}
		}
	}
}

.img-coming-soon {
	max-width: 165px;
	margin: auto;
}

.business-letter {
	position: relative;
	top: 45%;
	transform: translateY(-50%);
	font-size: 71px;
	color: #fff;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	font-family: Open Sans;
	font-weight: 700;
}
.business-offer-marker{
	position: absolute;
	width: 80px;
	top: -11px;
	z-index: 98;
	left: 7px;
	@media (max-width: 40.063em) {
        display: none;
    }
}

.business-offer-marker-mobile{
    display: none;
	@media (max-width: 40.063em) {
        display: block;
        width: 19%;
        top: -7px;
        position: absolute;
        left: 79%;
        z-index: 98;
    }
}

.business-happy-counter {
    position: absolute;
    display: flex;
    z-index: 99;
    background-color: #ffffff;
    padding: 2px 5px 2px 2px;
    border-radius: 7px;
    border: 1px solid black;
    cursor: pointer;

    .logo {
        max-width: 20px;
        max-height: 20px;
        margin: auto 4px auto 0;
    }
    .business-happy-count {
        font-size: 13px;
    }

    @media (max-width: 40.063em) {
        .heart {
            width: 14px;
            float: left;
        }
        .business-happy-count {
            font-size: 11px;
            padding-top: 1px;
        }
    }
}

ul#categories {
	background-color: #ffffff;
	border: 1px solid $pale-grey;
	border-bottom: none;
	clear: both;
	padding: 0;
	margin-bottom: 10px;
	li {
		margin: 0;
		a {
			font-size: 17px;
			@media (max-width: 64.063em) {
				font-size: 20px;
			}
			font-weight: 100;
			padding: 10px;
			border-bottom: 1px solid $pale-grey;
			color: #000;
		}
		&.active a {
			background: $off-white;
		}
	}
}

#weather{
	display:none;
}
#weather-button{
	float: right;
	border-radius: 5px;
	-moz-border-radius: 5px;
	margin-bottom: 7px;
	margin-right: 0;
	padding: 5px;
	cursor: pointer;
	box-shadow: 0 1px 0 0px #DDD;
	-moz-box-shadow: 0 1px 0 0px #DDD;
	border: 1px solid #DDD;
	position: relative;
	background: #fff;
	@media (max-width: 64.063em) {
		display: none;
	}
}
#weather-button:hover {
	box-shadow: none;
	top: 1px;
}

#pricing-container {
	overflow-x: scroll;
}

select#category {
	-webkit-appearance: none !important;
}

// Add overlay when filters are open (mobile only)
.filters-overlay {
	display: none; // Hidden by default
  
	@media (max-width: 991px) {
		&.active {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			z-index: 999;
			display: block;
		}
	}
  
	// Ensure overlay is never shown on desktop
	@media (min-width: 992px) {
	  	display: none !important;
	}
}

.filters-container {
	margin: 0 calc(var(--bs-gutter-x)*.5);

	.close-filters-button {
		display: none;
		padding: 10px;
		background-color: $primary-color;
		color: $white;
	}

	.filters-block {
		margin-bottom: 30px;
		background: white;
		padding: 10px;
		border: 1px solid $pale-grey;
		display: flex;
		flex-direction: column;

		&.collapsed {
			display: none;
		}

		.filter-header {
			display: flex;
			cursor: pointer;

			h4 {
				color: darken($primary-dark, 20%);
				font-size: 1.125rem;
				flex-grow: 1;
			}

			.toggle-icon {
				font-size: 24px;
				transition: transform 0.3s ease;
				margin-bottom: auto;
			}

			&.active {
				h4 {
					margin-bottom: 20px;
					padding-bottom: 10px;
					border-bottom: 1px solid $pale-grey;
				}
			}
		}

		.filter-group {
			margin-bottom: 15px;
			width: 100%;
			
			&:last-child {
				margin-bottom: 0;
			}

			// Row layout for label and switch
			&.switch-group {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		// Switch Pill Styling
		.switch-pill {
			position: relative;
			width: 46px;
			height: 24px;

			.switch-input {
				opacity: 0;
				width: 0;
				height: 0;

				&:checked + .switch-label {
					background-color: $primary-color;
					
					.switch-button {
						transform: translateX(22px);
					}
				}
			}

			.switch-label {
				position: absolute;
				margin: auto;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: $pale-grey;
				transition: .3s;
				border-radius: 24px;
				width: 46px;

				.switch-button {
					position: absolute;
					height: 18px;
					width: 18px;
					left: 3px;
					bottom: 3px;
					background-color: $white;
					transition: .3s;
					border-radius: 50%;
					box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
				}
			}
		}

		// Distance Select Styling
		select {
			width: 100%;
			padding: 8px 12px;
			border: 1px solid $pale-grey;
			border-radius: 6px;
			background-color: $white;
			color: lighten($primary-dark, 20%);
			font-size: 0.95rem;
			margin: 5px 0;
			height: auto;
			
			&:focus {
				border-color: $primary-color;
				outline: none;
			}
		}

		&.collapsed {
			.filter-content {
				display: none;
			}
		}

		@include bp(small) {
			padding: 10px;

			.filter-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;
				padding: 10px;
				
				h4 {
					margin: 0;
				}

				&.active .toggle-icon {
					transform: rotate(180deg);
				}
			}

			// Always show location services when disabled
			.filter-group.location-services {
				display: block !important;
				padding: 10px;
				border-top: 1px solid $pale-grey;
			}
		}

		// Update the toggle icon styling
		.toggle-icon {
			transition: transform 0.3s ease;
		}

		&.collapsed .toggle-icon {
			transform: rotate(0deg);
		}

		&:not(.collapsed) .toggle-icon {
			transform: rotate(180deg);
		}
	}

	@media (max-width: 991px) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		margin: 0;
		transform: translateY(100%);
		transition: transform 0.3s ease-in-out;
		background: white;
		max-height: 80vh;
		overflow-y: auto;
		box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

		&.active {
			transform: translateY(0);
		}

		.filters-block {
			margin: 0;
			border: none;
			border-radius: 12px 12px 0 0;
			padding-bottom: 20px;

			.close-filters-button {
				display: block;
				position: sticky;
				top: 0;
				right: 0;
				z-index: 1;
				margin: 0 0 10px auto;
				border: none;
				background-color: unset;
				color: $secondary-color;
			}
		}
	}
}

.filter-content {
	@include bp(small) {
		padding-top: 10px;
	}
}

.search-block {
	display: flex;
	margin-bottom: 30px;
	position: relative;
	margin: 0 calc(var(--bs-gutter-x)*.5);

	&__search-input {
		height: 55px;
		margin: 0;
	}

	.filter-button {
		height: 55px;
		margin: 0;
		padding: 1rem;
		box-shadow: none;
		background-color: $primary-color;
		color: $white;
	}

	input[type="text"].search-block__search-input {
		height: 55px;
		margin: 0;
		box-shadow: none;
	}

	&__search-clear-button {
		display: none;
		position: absolute;
		top: 10px;
		left: 5px;
		padding: 8px 15px;
		margin: 0;
		border-radius: 18px;
		background-color: #fff;
		color: #444;

		&:hover, &:focus {
			background-color: #c5c5c5;
		}
	}

	&__search-button {
		border: none;
		background-color: #666666;
		color: #fff;
		padding: 15px;
		font-size: 15px;
		flex-basis: 180px;
	}

	&--with-input {
		.search-block__search-clear-button {
			display: block;
		}
		.search-block__search-input {
			padding-left: 50px;
		}
	}
}

#consumer-page {
  #breadcrumbs {
    display: flex;
    margin-top: -30px;
    height: 30px;

    h1, h2, h3 {
      font-size: 0.75rem;
      font-weight: bold;
      margin: auto 0;

      // Bullet point
      &:before {
          content: '\2022';
          margin: 0 4px 0 8px;
      }
    }
  }
}

/* For Consumer Marketing Page */

#forconsumer {
	.welcome-section {
		text-align: center;
		color: #343434;
		padding: 30px 15px;

		h3 {
			font-size: 2rem;
			font-weight: bold;
			color: #343434;
		}

		.saving-tidbits__heading {
			font-weight: bold;
			margin: 30px 0;
			font-size: 2rem;
		}
		.saving-tidbits__tidbits-container {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
		.saving-tidbits__tidbit {
			margin: 15px 80px;
			font-size: 1.5rem;
		}
		.saving-tidbits__tidbit-image::before {
			font-size: 100px;
		}
		.saving-tidbits__tidbit-label {
			font-weight: bold;
		}

		@media screen and (min-width: 1000px) {
			h3 {
				font-size: 3rem;
			}
		}
	}
}

.flex-container {
	display: flex;
	flex-wrap: wrap;

	& > div {
		background-color: #f5f5f5;
		flex: 1;
		min-width: 350px;

		ul, li, img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.flex-container-content {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			padding: 15px 30px;

			h4 {
				font-weight: bold;
			}
			.button {
				font-size: 1rem;
			}

			.flex-container-content--larger-font {
				font-size: 170%;
			}

			@media screen and (min-width: 1000px) {
				padding: 30px 100px;
				h4 {
					font-size: 2rem;
				}
				p {
					font-size: 1.4rem;
				}
			}
		}
	}
}

/* End For Consumer Marketing Page */
@include bp(large) {
	select#category {
		/*float: left;*/
		/*margin-top: 10px;*/
		//-webkit-appearance: menulist !important;
		background: initial;
		border-radius: 0;
		color: #4d4d4d;
		cursor: pointer;
		font-size: 15px;
		margin-bottom: 0;
		padding-left: 1.5rem;
		width: 34%;
        min-height: 54px;
	}
}

#show-offers-label{
	margin:0px;
	font-size:16px;
}

.plus {
    display: none;
}

.pagination-centered {
	position: relative;
	#back-to-top {
		position: absolute;
		top: 0;
		right: 15px;
	}
}

// For Mobile
@media only screen and (max-width: 768px) {
    .plus {
        width: 25px;
        display: block;
        float: right;
        position: relative;
        top: 50%;
        right: 14px;
        transform: translateY(100%);
    }


	.row [class*="column"]:last-child .business-display .business-container {
		border-bottom: 1px solid $pale-grey;
	}
	.pagination-centered {
		margin-top: 30px;
	}

	form input[type="submit"] { font-size: 13px; }
}

.cluster { color:#333333; cursor:pointer !important; font-size:14px; text-align:center; }
.cluster-1 { background-image:url(../../images/marketing/cluster-m1.png); width:90px; height:80px; line-height:79px; }
.cluster-2 { background-image:url(../../images/marketing/cluster-m2.png); width:90px; height:80px; line-height:79px; }
.cluster-3 { background-image:url(../../images/marketing/cluster-m3.png); width:90px; height:80px; line-height:79px; }

input[type=checkbox]{
	/* Double-sized Checkboxes */
	-ms-transform: scale(1.3); /* IE */
	-moz-transform: scale(1.3); /* FF */
	-webkit-transform: scale(1.3); /* Safari and Chrome */
	-o-transform: scale(1.3); /* Opera */
	padding: 10px;
}

#bubble-bookmark > div {
  width: 320px !important;
  padding: 50px 20px !important;
  font-size: 16px !important;

  > div:first-child {
    margin: 20px 20px 20px 5px !important;
  }

  > div:not(:first-child) {
	  width: 38px !important;
	  height: 60px !important;
      left: 50% !important;
  }
}

.grecaptcha-badge { visibility: hidden; }

.geolocate-button {
  display: flex;
  margin: auto;
  align-items: center;
  font-size: 15px;

  .material-symbols-outlined {
    font-size: 1.5rem;
    margin-right: 10px;
  }
}

// Location prompt styling
.location-prompt {
    background: rgba(255, 255, 255, 0.9);
    padding: 25px;
    margin: 20px calc(var(--bs-gutter-x)* .5);
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    h2 {
        color: black;
        font-size: 1.75rem;
        font-weight: 600;
        margin-bottom: 0;
        
        @include bp(medium) {
            font-size: 4vw;
        }
    }

    p {
        color: lighten(black, 20%);
        line-height: 1.5;
        margin-bottom: 0;
        
        @include bp(medium) {
            font-size: 1rem;
        }

		&.location-prompt_title {
			font-size: 1.25rem;
		}

		&.location-prompt_description {
			font-size: 0.85rem;
			margin-bottom: 15px;

			&.emphasis-1 {
				font-size: 1rem;
			}

			&.emphasis-2 {
				font-size: 1.25rem;
			}

			@include bp(medium) {
				font-size: 2vw;
			}
		}
    }

    .geolocate-button {
        background-color: $primary-color;
        color: white;
        padding: 8px 16px;
        border-radius: 20px;
        font-size: 1rem;
        display: inline-flex;
        align-items: center;
        gap: 6px;
        border: none;
        transition: background-color 0.2s ease;
        cursor: pointer;

        &:hover {
            background-color: darken($primary-color, 8%);
        }

        .material-symbols-outlined {
            font-size: 1.25rem;
        }
		
        @include bp(medium) {
            font-size: 3vw;

			.material-symbols-outlined {
				font-size: 3.25vw;
			}
        }
    }
}

// Update the 'Search Near Me' section styling
#business-listings {
    .row {
		margin: 20px calc(var(--bs-gutter-x)* .5);
		
        
        .column {
            padding: 20px;
            background: #f5f5f5;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            
            h3 {
                font-size: 1.5rem;
                margin-bottom: 1rem;
                color: #333;
            }
            
            p {
                font-size: 1rem;
                color: #666;
                margin-bottom: 1.5rem;
            }
            
            .geolocate-button {
                background-color: $primary-color;
                color: white;
                padding: 12px 24px;
                border-radius: 25px;
                font-size: 1rem;
                border: none;
                cursor: pointer;
                transition: background-color 0.3s ease;
                
                &:hover {
                    background-color: darken($primary-color, 10%);
                }
            }
        }
    }
}

// Mobile dropdown specific styling
.top-bar-section {
	li {
		background: unset !important;
	}

	.install-pwa-button {
		display: flex;
		width: 100%;
		height: 30px;
		font-size: 13px;
		line-height: 1em;
		text-align: left;
		padding: 10px 15px;
		border-radius: 0;
		background-color: $primary-color;
		color: white;

		.material-symbols-outlined {
			font-size: 13px;
		}

		.button-text {
			display: none;
		}
	}
}


.top-bar.expanded .top-bar-section {	
	.install-pwa-button {
		.material-symbols-outlined {
			margin-right: 10px;
		}

		.button-text {
			display: block;
		}
	}
}

.location-input-group {
    margin-bottom: 20px;
    
    .location-input-wrapper {
        display: flex;
        align-items: center;
        border: 1px solid $pale-grey;
        border-radius: 6px;
        overflow: hidden;
    }

    .location-input {
        flex: 1;
        padding: 20px 12px;
		margin: 0;
        border: none;
        
        &:focus {
            outline: none;
        }
    }

    .location-submit {
        background: $primary-color;
        border: none;
        padding: 11px 12px;
		margin: 0;
        cursor: pointer;
        color: white;
        display: flex;
        align-items: center;
        transition: background-color 0.2s ease;
        
        &:hover {
            background-color: darken($primary-color, 8%);
        }

        .material-symbols-outlined {
            font-size: 18px;
        }
    }
}
