.vert-center {
	display: table;
	width: 100%;
	.vert-content {
		display: table-cell;
		vertical-align: middle;
	}
}

.relative {
  position: relative;
}

.bg-primary {
  background-color: $primary-color;
  &.bg-transparent {
    background-color: rgba($primary-color, .75);
  }
}

.bg-secondary {
  background-color: $secondary-color;
  &.bg-transparent {
    background-color: rgba($secondary-color, .5);
  }
}

.border-primary {
  border-color: $primary-color !important;
}

.border-secondary {
  border-color: $secondary-color !important;
}