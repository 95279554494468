@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700|Open+Sans:300,400,600,700);

ul {
    list-style-type: none;
}

.font-100 {
  font-weight: 100 !important;
}

.font-300 {
  font-weight: 300 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.color-primary {
  color: $primary-color !important;
}

.color-secondary {
  color: $secondary-color !important;
}

.color-white {
  color: $white !important;
}
