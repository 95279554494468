footer {
    background: darken($primary-dark, 5%);
    padding: 20px 0 0;
    &, a {color: $white;}
    ul {
        text-align: center;
        list-style-type: none;
        font-size: rem-calc(13);
        margin-left: 0;
        li {
            display: inline-block;
            margin: 5px 10px;
            font-weight: 600;
            a:focus, a:hover { color: $secondary-color; }
        }
    }
    .bottom-footer {
        padding: 20px 0;
        background: darken($primary-dark, 10%);
        p {
            font-size: 13px;
            margin: 10px 0;
            text-align: center;
        }
        select {
            margin: 0;
        }
    }
    @media (max-width: 64.063em) { 
        text-align: center;
        p {
            text-align: center;
        }
        ul {
            float: none;
            width: 100%;
            text-align: center;
        }
    }
}
