/* Styles for the consumer/contact pages
---------------------------------------- */
#contact-header-banner{
	background: url(../../images/contact/ContactUsHeader.jpg) center;
	background-size: cover;
	color: white;
	min-height: 400px;
	padding-top: 8rem;
	text-align: center;
	h1{
		color: white;
		font-size: 2.5rem;
		font-weight: bold;
		text-shadow: -1px 2px 5px transparentize(black, .5);
		text-transform: uppercase;
	}
	h2{
		color: white;
		font-size: 1.5rem;
		text-shadow: -1px 2px 5px transparentize(black, .5);
	}
  	@media screen and (min-width: 1000px) {
      	h1 {
			font-size: 4.5rem;
		}
		h2 {
			font-size: 2rem;
		}
  	}
}
#contact-content{
	.contact-intro{
		border-bottom: 1px solid transparentize(black, .9);
		margin-bottom: 2rem;
		text-align: center;
		h1{
			color: #676767;
			font-size: 2rem;
			padding: 2rem 0;
		}
	}
	.form-contain{
		padding: 1rem 3rem;
	}

	@media screen and (min-width: 1000px) {
		.contact-intro {
			h1 {
				font-size: 3rem;
			}
		}
	}
}
#contact-sidebar{
	padding: 1rem;
	h1{
		font-size: 2rem;
	}
	img{
		margin-bottom: 1rem;
	}
}
